import React from 'react';
import { Card, Row, Col, Tabs, Tab, Table } from 'react-bootstrap';

const StockInformation = ({ stats, bs, videos }) => {
    console.log('bs prop:', bs);
    console.log('stats prop:', stats);
    console.log('videos prop:', videos);

    if (!stats || !stats.Name) {
        return <p>No stock information available.</p>;
    }

    // Helper function to format numbers as currency
    const formatCurrency = (value) => {
        if (value && !isNaN(parseFloat(value))) {
            return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
        return 'N/A';
    };

    return (
        <Card className="mb-4">
            <Card.Body className="text-start">
                <h2>Stock Information</h2>

                {/* Tabs for Fundamentals, Options, and Videos */}
                <Tabs defaultActiveKey="fundamentals" id="stock-info-tabs" className="mb-3">
                    <Tab eventKey="fundamentals" title="Fundamentals">
                        {/* Fundamentals Section */}
                        <Row className="mb-3">
                            <Col><p><strong>Company Name:</strong> {stats.Name}</p></Col>
                            <Col><p><strong>Symbol:</strong> {stats.Symbol}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p><strong>Description:</strong> {stats.Description}</p></Col>
                        </Row>

                        {/* Additional Stock Information */}
                        {/* ... */}
                    </Tab>

                    <Tab eventKey="options" title="Options">
                        {/* Options Section */}
                        {/* Ensure bs is defined and contains the necessary properties */}
                        {bs && bs.contractID ? (
                            <Table striped bordered hover>
                                {/* Render options data */}
                            </Table>
                        ) : (
                            <p>No options data available.</p>
                        )}
                    </Tab>

                    {/* New Videos Tab */}
                    <Tab eventKey="videos" title="Videos">
                        {videos && videos.length > 0 ? (
                            <div>
                                {videos.map((video, index) => (
                                    <div key={index} className="mb-4">
                                        <h5>{video.title}</h5>
                                        <p>
                                            <strong>Published:</strong> {video.published} by {video.author}<br />
                                            <strong>Views:</strong> {video.views}<br />
                                            <strong>Duration:</strong> {video.duration}
                                        </p>
                                        <div className="video-container">
                                            <iframe
                                                src={`https://www.youtube.com/embed/${video.id}`}
                                                title={video.title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No videos available.</p>
                        )}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
};

export default StockInformation;
