import React, { useState, useEffect, useRef } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ChatBox = ({ ticker }) => {
    const [chatMessage, setChatMessage] = useState('');
    const [chatResponse, setChatResponse] = useState([]);
    const chatBoxRef = useRef(null);  // Ref for the chat display area

    // Handles form submission when the user sends a message
    const handleChatSubmit = async (e) => {
        e.preventDefault();

        if (!chatMessage.trim()) return;  // Prevent empty submissions

        // Add user's message to the chat history
        setChatResponse(prev => [...prev, { user: 'User', message: chatMessage }]);
        setChatMessage('');  // Clear the chat input after submission

        try {
            // Send message to Flask backend at the /chat route
            const response = await axios.post(`/api/chat`, {  // Using /api/chat as the correct route
                message: chatMessage,
                ticker: ticker,
            });

            // Add bot's response to the chat history
            setChatResponse(prev => [...prev, { user: 'Bot', message: response.data.response }]);
        } catch (err) {
            // Handle error case
            setChatResponse(prev => [...prev, { user: 'Bot', message: 'Error occurred while fetching chat response.' }]);
        }
    };

    // Scroll to the bottom of the chatbox when chatResponse updates
    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;  // Scroll the chat box to the latest message
        }
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever chatResponse updates
    }, [chatResponse]);

    return (
        <Card className="mb-4" style={{ height: '700px', display: 'flex', flexDirection: 'column' }}>
            <Card.Body style={{ padding: '0', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <h4 className="p-3">Stockato AI</h4>
                {/* Chat display area */}
                <div 
                    ref={chatBoxRef}  // Attach the ref to the scrollable chat area
                    style={{
                        flexGrow: 1,
                        overflowY: 'auto',  // Enable scrolling for the chat content
                        padding: '1rem',
                        borderBottom: '1px solid #ccc',
                        maxHeight: '500px',  // Fixed height for the chat area
                    }}
                >
                    {chatResponse.length > 0 ? (
                        chatResponse.map((chat, index) => (
                            <div key={index} className="chat-response">
                                <strong>{chat.user}:</strong>
                                <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{chat.message}</p>
                            </div>
                        ))
                    ) : (
                        <p>No messages yet.</p>
                    )}
                </div>
                {/* Chat input form at the bottom */}
                <Form onSubmit={handleChatSubmit} className="p-3">
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Ask a question about the stock"
                            value={chatMessage}
                            onChange={(e) => setChatMessage(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">Send</Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ChatBox;
