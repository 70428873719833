import React, { useState } from 'react';
import axios from 'axios';
import StockInformation from './StockInformation';
import RecentNews from './RecentNews';
import StockChart from './StockChart';  // Import the StockChart component
import ChatBox from './ChatBox';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const StockSearch = () => {
    const [tickerInput, setTickerInput] = useState(''); // Keep a separate state for the input field
    const [ticker, setTicker] = useState('');           // The actual ticker for the search
    const [stockData, setStockData] = useState(null);
    const [analysisText, setAnalysisText] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // This function runs when the search form is submitted
    const handleSearch = async (e) => {
        e.preventDefault();
        setTicker(tickerInput); // Set the actual ticker when the user submits the form
        await fetchStockData(tickerInput); // Use tickerInput to fetch data
    };

    const fetchStockData = async (ticker) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/stock/${ticker}`);
            console.log("API Response:", response.data);
            setStockData(response.data);
            setAnalysisText(response.data.analysis_text);
            setError(null);
        } catch (err) {
            setError('Failed to fetch stock data');
            setStockData(null);
            setAnalysisText('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="p-4">
            <Row>
                <Col md={3}>
                    {/* Handle form submission, but don't trigger changes on each keystroke */}
                    <Form onSubmit={handleSearch} className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            value={tickerInput}  // Keep track of input separately
                            onChange={(e) => setTickerInput(e.target.value)}  // Only update input state
                            placeholder="Enter stock ticker"
                            required
                            className="me-2"
                        />
                        <Button variant="primary" type="submit">
                            Search
                        </Button>
                    </Form>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={8}>
                    {stockData && (
                        <>
                            {/* Removed the 1 Year and 3 Year buttons */}
                            <div style={{ height: '800px', marginBottom: '0px' }}>
                                <StockChart symbol={ticker} />
                            </div>
                        </>
                    )}
                </Col>

                <Col md={4}>
                    <ChatBox ticker={ticker} />
                </Col>
            </Row>

            {stockData && (
                <Row className="mt-1">
                    <Col md={8} className="text-start">
                        <StockInformation stats={stockData.stats} bs={stockData.bs} videos={stockData.videos} />
                        <RecentNews newsData={stockData.news_data} />
                    </Col>
                    <Col md={4} className="text-start">
                        {analysisText && (
                            <div>
                                <h3>Stock Analysis</h3>
                                <div
                                    className="reddit-comments"
                                    style={{
                                        wordBreak: 'normal !important',
                                        whiteSpace: 'normal !important',
                                        overflowWrap: 'break-word! important',
                                        lineHeight: '1.5',
                                        maxWidth: '600px',
                                        margin: '0 auto',
                                    }}
                                >
                                    {analysisText.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            )}

            {loading && <p className="text-center">Loading...</p>}
            {error && <p className="text-danger text-center">{error}</p>}
        </Container>
    );
};

export default StockSearch;
