import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';

const StockChart = ({ symbol }) => {
  const defaultSymbol = symbol || 'QQQ'; // Default to AAPL if no symbol is provided

  return (
    <div>
      <TradingViewWidget
        symbol={defaultSymbol}
        width="100%"
        height="700"
        theme="light"
        interval="D"  // Daily interval, you can change this
        locale="en"
        hide_side_toolbar={false}
      />
    </div>
  );
};

export default StockChart;
